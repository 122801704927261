import OrangesOnFire from './images/orangesonfire.jpg';
import Kubismus from './images/kubismus.jpg';

export default function ErweiterteWelten(props) {
  const exhibData = {
    "lippenlesen" : <iframe title="lippenlesen" src="https://player.vimeo.com/video/781016895?h=17c4464fae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "intheflood" : <iframe title="intheflood" src="https://player.vimeo.com/video/781017872?h=ff6582fd88&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "wasser" : <iframe title="wasser" src="https://player.vimeo.com/video/781021927?h=e4836272de&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "rinde" : <iframe title="rinde" src="https://player.vimeo.com/video/780919201?h=5e63abb88a&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "viet" : <iframe title="viet" src="https://player.vimeo.com/video/780915665?h=a17bd302ee&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "karawane" : <iframe title="karawane" src="https://player.vimeo.com/video/780917976?h=f32d2028a7&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "desktopspiral" : <iframe title="desktopspiral" src="https://player.vimeo.com/video/780913404?h=3129e0ad16&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "shorba" : <iframe title="shorba" src="https://player.vimeo.com/video/780910350?h=e24a93acc6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>,
    "kubismus": <img src={Kubismus} alt=""></img>,
    "orangesonfire": <img src={OrangesOnFire} alt=""></img>
  }
  
  return (
    <div className="w-screen h-screen">
      {exhibData[props.name]}
    </div>
  )
}