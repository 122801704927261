import { useEffect } from 'react';
import AdArt from './AdArt.jsx';
import './OpenCall.css';
import RouteMarl from './images/Marl_Route.pdf'; 

export default function OpenCall() {
  useEffect(() => {
    document.title = "opencall | Augmented Art Advertising";

    document.querySelectorAll(".info").forEach((elem) => {
      elem.classList.add("font-semibold");
      elem.classList.add("text-black");

    });
  }, []);

  return (
    <>
      <div id="opencall" className="bg-gradient-to-b from-grey to-yellow text-[24px] 
      text-stone-800 tracking-wider overflow-x-hidden h-screen w-screen">

        <div className="mt-8 ml-12 mr-12 w-full">
        <div className="float-right mr-24">
            <button id="de" className="text-white"
            onClick={(event) => {
              document.getElementById("content-de").classList.remove("-translate-x-[100%]");
              document.getElementById("content-en").classList.add("translate-x-[100%]");

              event.target.classList.add("text-white");
              document.getElementById("en").classList.remove("text-white");
            }}>DE</button>
            |
            <button id="en"
            onClick={(event) => {
              document.getElementById("content-de").classList.add("-translate-x-[100%]");
              document.getElementById("content-en").classList.remove("translate-x-[100%]");
              event.target.classList.add("text-white");
              document.getElementById("de").classList.remove("text-white");
            }}>EN</button>
          </div>
          <h2 className="inline-block text-[36px] tracking-[0.5em] text-black font-extd"><a href="/" title="Home | Augmented Art Advertising"><AdArt /></a>|<div className="inline-block">Open Call</div></h2>
        </div>

        <div className="relative">

          <div id="content-de" className="absolute top-24 transition-all duration-300 pl-12 pr-12 pb-24">
            <p className='info-dark'>
              Update: Die Deadline für Einreichungen wird bis zum 01.07.2022 verlängert!
            </p>
            <p className="mt-4">
              Im August 2022 lädt das Skulpturenmuseum Glaskasten Marl zu einer virtuellen
              <AdArt />-Ausstellung mit Kunstwerken im AR-Raum ein.
              Dabei wird mit der Augmented-Reality-Umgebung Werbearchitektur als
              Ausstellungsfläche für Kunstwerke genutzt (erfahre <a href="/" className="info-dark blink">hier</a> mehr über das Projekt).
            </p>
            <p className="mt-4">
              Gesucht werden sieben Positionen, die sich im Bereich der Medienkunst bewegen. Die Werke werden in der
              <AdArt />-App
              eingebettet und lassen sich vom <span className="info-dark">12.08. - 29.08.2022 in Marl virtuell</span> an einem vorab festgelegten Standort betrachten.
              Darüber hinaus werden die Arbeiten über die Projektlaufzeit weiter in der Sammlung der App zu finden sein. 
            </p>
            <p className="mt-4">       
              Arbeiten können als Bild- und Videoformate eingereicht werden. Eine Einreichung kann auch aus einer Reihe von Bildern und/oder Videos bestehen.
              Es ist möglich, bestehende Arbeiten einzureichen, jedoch werden Arbeiten bevorzugt, die dezidiert für das Projekt 
              <AdArt /> entstehen
              und Bezug auf die Stadt Marl und die bespielten Orte nehmen oder das Thema "Augmented Reality" in besonderer Weise behandeln.
              Die Orte sind auf einer Karte eingezeichnet, die der Orientierung dient und die 
              hier zum <a className="info-light" href={RouteMarl} target="_blank" rel="noreferrer">Download</a> bereitsteht. 
            </p>
            <p className="mt-4">
            Pro ausgewählter Arbeit erhalten KünstlerInnen ein nach Aufwand gestaffeltes Honorar <span className="info-dark">zwischen 250 und 1000 Euro</span>. 
              <br /> <span className="info-dark"> Deadline</span> für die Einreichungen ist der <span className="line-through">26.06.2022</span> <span className="info-dark">01.07.2022</span>.
            </p>
            <p className="mt-4">
              Bist du an dem OPEN CALL interessiert?
              Richte deine <span className="info-dark">Fragen, Anmerkungen und Einreichungen</span> per E-Mail an: <a className="text-white font-bold" href="mailto:info@augmented-art-advertising.com">info@augmented-art-advertising.com</a>
            </p>
          </div>

          <div id="content-en" className="absolute col-start-2 text-white top-24 translate-x-[100%] transition-all duration-300 pl-6 pr-6 pb-24">
            <p className='info-light'>
              Update: The deadline for submissions extends to July 01, 2022!
            </p>
            <p className="mt-4">
              In August 2022, the Skulpturenmuseum Glaskasten Marl invites you to a virtual
              <AdArt />exhibition with artworks for the AR space.
              With the augmented reality environment, advertisement architecture is used as an exhibition space for media art (learn <a href="/" className="info-dark blink">more</a> about the project).
            </p>
            <p className="mt-4">
              We are looking for seven positions that are based in the field of media art. The artworks will be embedded in the
              <AdArt />-app
              and will be virtually exhibited from  <span className="info-light">12.08. - 29.08.2022 in Marl </span> at a fixed location. They also will be part of the art collection inside the app.
              Supported are image and video formats. It is possible to send us an artwork which consists of multiple images and/or videos. You can submit any kind of work,
              however we will preferably select works, that are site specific to Marl or reflect the topic 'Augmented Reality' in a special way. 
              You can <a className="info-dark" href={RouteMarl} target="_blank" rel="noreferrer">download</a> the map of the specific places in Marl, that are part of the exhibition.  
            </p>
            <p className="mt-4">
              We offer an expense allowance of <span className="info-light">250 to 1000 Euro</span> for each work selected.
              <br /><span className="info-light"> Deadline</span> for submissions is <span className="line-through">June 26, 2022</span> <span className='info-light'>July 01, 2022</span>.
            </p>
            <p className="mt-4">
              Interested in the OPEN CALL?
              Send us an email to ask questions, give feedback or submit your artwork: <a className="text-black font-bold" href="mailto:info@augmented-art-advertising.com">info@augmented-art-advertising.com</a>
            </p>
          </div>

        </div>
      </div>
    </>
  )
}