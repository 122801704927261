export default function Datenschutz() {
  return (
    <div id="datenschutz-content" className="p-12">
      <h1 className="font-bold mb-4 text-[36px]">Datenschutz</h1>
      <p className="text-sm mb-2 font-thin">Wir stellen Ihnen die mobile App adARt (nachfolgend: App) zur Verfügung, die Sie auf Ihr iOS- bzw. Ihr Android-Endgerät herunterladen können. Alle personenbezogenen Daten werden vertraulich behandelt. Unsere Datenschutzpraxis steht im Einklang mit dem Bundesdatenschutzgesetz (BDSG) und der Datenschutzgrundverordnung (DSGVO). Nachfolgend informieren wir Sie über die Einzelheiten zum Datenschutz:</p>
      <h2 className="font-bold text-lg">1.Verantwortliche Stelle im Sinne der DSGVO und des BDSG</h2>
      <p className="text-sm mb-2 font-thin">
        Verantwortlich für die Datenerhebung- und Verarbeitung ist: <br />
        Delidova & Popien Kollektiv 42 GbR <br />
        Bruchstr. 42, 45327 Essen <br />
        Telefon: +49 (0) 1786603814 <br />
        E-Mail: <a className="text-blue-600 hover:text-white" href="mailto:anastasija.delidova@googlemail.com">anastasija.delidova@googlemail.com</a> 
      </p>
      <h2 className="font-bold text-lg">2.Gründe für eine Datenerfassung</h2>
      <p className="text-sm mb-2 font-thin">Wir erfassen und verarbeiten Ihre Daten zur Bereitstellung unserer App, insbesondere zur Bereitstellung von Augmented Reality Inhalten.</p>
      <h2 className="font-bold text-lg">3.Erfassung, Verarbeitung und Nutzung von Daten</h2>
      <h3 className="font-bold text-lg">3.1.Download der App</h3>
      <p className="text-sm mb-2 font-thin">Bei Herunterladen der App werden die erforderlichen Informationen an den Apple App Store bzw. Google Play Store übertragen, also insbesondere Nutzername, E-Mail-Adresse und Kundennummer Ihres Kontos, Zeitpunkt des Downloads, Zahlungsinformationen und die individuelle Gerätekennziffer. Auf diese Datenerhebung haben wir keinen Einfluss und sind nicht dafür verantwortlich; Sie haben ggf. gegenüber Apple oder Google in die Verwendung der Daten zu diesem Zweck eingewilligt. Die Daten werden nur verarbeitet, soweit es für das Herunterladen der mobilen App auf Ihr mobiles Endgerät erforderlich ist.</p>
      <h3 className="font-bold text-lg">3.2.Benutzung der App</h3>
      <p className="text-sm mb-2 font-thin">Bei Nutzung der App erheben wir die nachfolgend beschriebenen personenbezogenen Daten, um die Nutzung der App zu ermöglichen. Wenn Sie unsere App nutzen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Funktionen unserer mobilen App anzubieten und die Stabilität und Sicherheit zu:</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">IP-Adresse;</li>
        <li className="list-disc text-sm mb-2 font-thin">Datum und Uhrzeit der Anfrage;</li>
        <li className="list-disc text-sm mb-2 font-thin">Zeitzonendifferenz zur Greenwich Mean Time (GMT);</li>
        <li className="list-disc text-sm mb-2 font-thin">Inhalt der Anforderung (konkrete Seite);</li>
        <li className="list-disc text-sm mb-2 font-thin">Zugriffsstatus/HTTP-Statuscode;</li>
        <li className="list-disc text-sm mb-2 font-thin">jeweils übertragene Datenmenge;</li>
        <li className="list-disc text-sm mb-2 font-thin">Website, von der die Anforderung kommt;</li>
        <li className="list-disc text-sm mb-2 font-thin">Verwendeter Browser;</li>
        <li className="list-disc text-sm mb-2 font-thin">Betriebssystem und dessen Oberfläche;</li>
        <li className="list-disc text-sm mb-2 font-thin">Sprache und Version der Browsersoftware.</li>
      </ul>
      <p className="text-sm mb-2 font-thin">In Ergänzung zu vorstehenden Daten erheben und verarbeiten wir die folgenden Daten:</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">Gerätekennzeichnung Ihres mobilen Endgeräts;</li>
        <li className="list-disc text-sm mb-2 font-thin">eindeutige Nummer des Endgerätes (IMEI = International Mobile Equipment Identity);</li>
        <li className="list-disc text-sm mb-2 font-thin">eindeutige Nummer des Netzteilnehmers (IMSI = International Mobile Subscriber Identity);</li>
        <li className="list-disc text-sm mb-2 font-thin">Mobilfunknummer (MSISDN);</li>
        <li className="list-disc text-sm mb-2 font-thin">MAC-Adresse für WLAN-Nutzung;</li>
        <li className="list-disc text-sm mb-2 font-thin">Name des mobilen Endgeräts.</li>
      </ul>
      <p className="text-sm mb-2 font-thin">Wir analysieren die erhobenen Daten automatisch, um funktionelle Beeinträchtigungen unserer Dienste sowie Angriffe auf unsere technische Infrastruktur (z. B. durch sog. Denial-of-Service-Angriffe) zu erkennen und erforderliche Gegenmaßnahmen ergreifen zu können. Rückschlüsse aus diesen Daten auf bestimmte Personen sind aufgrund einer von uns vorgenommenen Pseudonymisierung für uns nicht möglich. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen</p>
      <p className="text-sm mb-2 font-thin">Eine Löschung der genannten Daten erfolgt in der Regel nach 24 Stunden, bzw. bei länger andauernden Bedrohungslagen nach sieben Tagen, spätestens aber dann, wenn die Daten zu den vorgenannten Zwecken nicht mehr erforderlich sind. Falls gesetzliche Aufbewahrungspflichten bestehen, schränken wir die Verarbeitung ein.</p>
      <p className="text-sm mb-2 font-thin">Die Rechtsgrundlage zur Erhebung der Daten im Rahmen der Benutzung der App ist unsere vertragliche Verpflichtung zur Bereitstellung der Funktionen der App gem. Art. 6 Abs. 1 Satz 1 lit. b) DSGVO. Die Rechtsgrundlage für die Erhebung und die Analyse der erhobenen Daten ist ferner unser berechtigtes Interesse an der Gewährleistung der Stabilität und Sicherheit der Datenverbindung und unserer technischen Systeme im Sinne von Art. 6 Abs. 1 Satz 1 lit. f) DSGVO.</p>
      <h3 className="font-bold text-lg">3.3.Datenweitergabe an Google</h3>
      <p className="text-sm mb-2 font-thin">Zur Nutzung der Augmented Reality funktion nutzen wir Dienste der Google Inc. (www.google.de). Zu diesem Zweck werden Daten in der Regel an einen Server von Google in den USA übertragen und dort gespeichert (bitte beachten Sie unsere Hinweise zur Datenübertragung in einen unsicheren Drittstaat weiter unten).  Der Dienst läuft auf den Google Play-Diensten für AR (ARCore) https://play.google.com/store/apps/details?id=com.google.ar.core) sowie AR Kit (https://developer.apple.com/documentation/arkit/content_anchors/detecting_images_in_an_ar_experience) , die von Google bereitgestellt werden und den Datenschutzbestimmungen von Google unterliegen, die Sie hier (https://policies.google.com/privacy) einsehen können. Ihre Kamera- und Standortinformationen werden zu diesen Zwecken an Google weitergegeben.</p>
      <h4 className="font-bold text-lg">Firebase</h4>
      <p className="text-sm mb-2 font-thin">Wir verwenden den Firebase-Dienst von Google, um die Inhalte an die App zu liefern und Ihr Feedback sowie Ihre Eingaben in Augmented Reality zu speichern und mit anderen Geräten zu teilen. Außerdem analysieren wir das Nutzerverhalten über Firebase, um den Dienst zu verbessern. Ihre eindeutige Benutzer-ID und Ihre Kontoinformationen werden zu diesen Zwecken an Google weitergegeben. Die Datenschutzerklärung von Google können Sie hier (https://policies.google.com/privacy) einsehen.</p>
      <p className="text-sm mb-2 font-thin">Die Rechtsgrundlage zur Erhebung der Daten im Rahmen der Benutzung der App ist unsere vertragliche Verpflichtung zur Bereitstellung der Funktionen der App gem. Art. 6 Abs. 1 Satz 1 lit. b) oder Ihre Einwilligung nach lit. a) DSGVO.</p>
      <h4 className="font-bold text-lg">Weitergabe an Drittländer:</h4>
      <p className="text-sm mb-2 font-thin">ACHTUNG. Dieser Service kann die erfassten Daten in ein Drittland im Sinne der DSGVO (Vereinigten Staaten von Amerika) übermitteln. Bitte beachten Sie, dass es sich hierbei um einen Staat handelt, welches derzeit kein angemessenes Datenschutzniveau bietet. Falls die Daten in die USA übertragen werden, besteht das Risiko, dass Ihre Daten von US-Behörden (Sicherheitsbehörden, Geheimdienste) zu Kontroll- und Überwachungszwecken verarbeitet werden können, ohne dass Ihnen gegen diese Verarbeitung dem europäischen und nationalen Rechtssystem vergleichbare Rechtsbehelfsmöglichkeiten zustehen.</p>
      <p className="text-sm mb-2 font-thin">Wenn Sie einwilligen, dass wir diesen Service einsetzen dürfen, erteilen Sie ebenfalls zu einer Datenübermittlung der hier aufgeführten Daten in die Vereinigten Staaten Ihr Einverständnis. Dieses Einverständnis können Sie jederzeit uns gegenüber mit Wirkung für die Zukunft widerrufen.</p>
      <p className="text-sm mb-2 font-thin">Wir haben mit dem Serviceanbieter darüber hinaus mit den Verarbeitungsunternehmen Standarddatenschutzklauseln sowie eine Ergänzung zu den Standarddatenschutzklauseln der EU-Kommission abgeschlossen, um einen angemessenen Schutz Ihrer Daten im Rahmen der Datenübermittlung in die Vereinigten Staaten von Amerika zu gewährleisten. Damit stellen wir sicher, dass ein dem europäischen Datenschutzniveau vergleichbares Schutzniveau gewährleistet werden kann.</p>
      <p className="text-sm mb-2 font-thin">Klicken Sie hier, um die Datenschutzbestimmungen des Datenverarbeiters zu lesen <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
      <p className="text-sm mb-2 font-thin">Klicken Sie hier, um auf allen Domains des verarbeitenden Unternehmens zu widerrufen <a href="https://safety.google/privacy/privacy-controls/">https://safety.google/privacy/privacy-controls/</a></p>
      <p className="text-sm mb-2 font-thin">Klicken Sie hier, um die Cookie-Richtlinie des Datenverarbeiters zu lesen <a href="https://policies.google.com/technologies/cookies?hl=en">https://policies.google.com/technologies/cookies?hl=en</a></p>
      <h3 className="font-bold text-lg">3.4.Ortungsdienste</h3>
      <p className="text-sm mb-2 font-thin">Zur Nutzung der Funktionen der App ist eine Aktivierung der Ortungsdienste auf dem Endgerät nicht erforderlich.</p>
      <p className="text-sm mb-2 font-thin">Falls Ortungsdienste genutzt werden ist Grundlage für die Erhebung, Speicherung und Verarbeitung dieser Daten Ihre Einwilligung. Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 lit. a) DSGVO.</p>
      <h3>3.5.Kamera</h3>
      <p className="text-sm mb-2 font-thin">Zur Nutzung der App, insbesondere der Augmented Reality Funktion, ist ein Zugriff auf die Kamera erforderlich. Der Zugriff ist Standardmäßig deaktiviert, muss aber aktiviert werden, um die Funktion nutzen zu können. Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 lit. a) DSGVO.</p>
      <h3 className="font-bold text-lg">3.6.Anfragen und Fehlermeldungen</h3>
      <p className="text-sm mb-2 font-thin">Wenn Sie uns über die in der App und dieser Datenschutzerklärung angegebenen Möglichkeiten (insb. über die angegebenen E-Mail-Adressen) kontaktieren oder eine Fehlermeldung absenden, erheben wir die darin gegebenenfalls enthaltenen folgenden personenbezogenen Daten:</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">Name,</li>
        <li className="list-disc text-sm mb-2 font-thin">E-Mail-Adresse,</li>
        <li className="list-disc text-sm mb-2 font-thin">Telefonnummer,</li>
        <li className="list-disc text-sm mb-2 font-thin">Betreff und</li>
        <li className="list-disc text-sm mb-2 font-thin">Nachrichteninhalt.</li>
      </ul>
      <p className="text-sm mb-2 font-thin">Die Daten sind zur Bearbeitung Ihrer Anfrage erforderlich. Die im Zusammenhang mit Ihrer Anfrage oder Fehlermeldung anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
      <p className="text-sm mb-2 font-thin">Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten ist unser berechtigtes Interesse an der Bearbeitung von Fehlermeldungen und Anfragen gem. Art. 6 Abs. 1 Satz 1 lit. f). DSVGO. Im Übrigen ist Rechtsgrundlage Art. 6 Abs. 1. Satz 1 lit. b) DSGVO, da wir zur Erfüllung unserer vertraglichen Pflichten ggf. Anfragen bearbeiten müssen.</p>
      <h3 className="font-bold text-lg">3.7.Einbindung externer Dienstleister</h3>
      <p className="text-sm mb-2 font-thin">Teilweise beauftragen wir zur Verarbeitung Ihrer Daten externe Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und, sofern eine Auftragsverarbeitung vorliegt, besteht mit diesen eine Vereinbarung zur Auftragsverarbeitung.</p>
      <h2 className="font-bold text-lg">4.Datensicherheit</h2>
      <p className="text-sm mb-2 font-thin">Wir sichern unsere App bzw. den darüber abgewickelten Datenverkehr und unsere sonstigen informationstechnischen Systeme durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen. Die Übertragung von Daten mit einer 128 bit oder 256 bit SSL-Verschlüsselung (Transportverschlüsselung). Die Transportverschlüsselung stellt sicher, dass Dritte, die über das Internet übertragene Daten abfangen, nicht von deren Inhalt Kenntnis erlangen können. Nicht zur Transportverschlüsselung geeignet sind Metadaten, also insb. Zeit der Datenübermittlung, IP-Adressen der Kommunikationsteilnehmer sowie Menge und Größe der übertragenen Daten.</p>
      <p className="text-sm mb-2 font-thin">Wir wiesen darauf hin, dass trotz regelmäßiger Kontrollen und stetiger Verbesserung unserer Sicherheitsmaßnahmen ein vollständiger Schutz gegen alle Gefahren nicht möglich ist.</p>
      <h2 className="font-bold text-lg">5.Löschung personenbezogener Daten</h2>
      <p className="text-sm mb-2 font-thin">Personenbezogene Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt oder Sie die Löschung verlangen. Eine Löschung der Daten erfolgt auch dann, wenn eine durch die genannte Norm vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder Vertragserfüllung besteht oder Sie Ihre diesbezügliche Einwilligung erteilt haben.</p>
      <h2 className="font-bold text-lg">6.Rechte der betroffenen Person</h2>
      <p className="text-sm mb-2 font-thin">Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener im Sinne der DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>
      <h3 className="font-bold text-lg">6.1.Auskunftsrecht</h3>
      <p className="text-sm mb-2 font-thin">Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.</p>
      <p className="text-sm mb-2 font-thin">Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
        <li className="list-disc text-sm mb-2 font-thin">die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
        <li className="list-disc text-sm mb-2 font-thin">die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</li>
        <li className="list-disc text-sm mb-2 font-thin">die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</li>
        <li className="list-disc text-sm mb-2 font-thin">das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</li>
        <li className="list-disc text-sm mb-2 font-thin">das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
        <li className="list-disc text-sm mb-2 font-thin">alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden.</li>
      </ul>
      <h3 className="font-bold text-lg">6.2.Recht auf Berichtigung</h3>
      <p className="text-sm mb-2 font-thin">Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
      <h3 className="font-bold text-lg">6.3.Recht auf Einschränkung der Verarbeitung</h3>
      <p className="text-sm mb-2 font-thin">Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</li>
        <li className="list-disc text-sm mb-2 font-thin">die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
        <li className="list-disc text-sm mb-2 font-thin">der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</li>
        <li className="list-disc text-sm mb-2 font-thin">wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
      </ul>
      <p className="text-sm mb-2 font-thin">Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.</p>
      <p className="text-sm mb-2 font-thin">Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>
      <h3 className="font-bold text-lg">6.4.Recht auf Löschung</h3>
      <h4 className="font-bold text-lg">6.4.1.Löschungspflicht</h4>
      <p className="text-sm mb-2 font-thin">Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
        <li className="list-disc text-sm mb-2 font-thin">Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1. Satz 1 lit. a) oder Art. 9 Abs. 2 lit. a) DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
        <li className="list-disc text-sm mb-2 font-thin">Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
        <li className="list-disc text-sm mb-2 font-thin">Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
        <li className="list-disc text-sm mb-2 font-thin">Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
        <li className="list-disc text-sm mb-2 font-thin">Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>
      </ul>
      <h4 className="font-bold text-lg">6.4.2.Information an Dritte</h4>
      <p className="text-sm mb-2 font-thin">Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
      <h4 className="font-bold text-lg">6.4.3.Ausnahmen</h4>
      <p className="text-sm mb-2 font-thin">Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
        <li className="list-disc text-sm mb-2 font-thin">zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
        <li className="list-disc text-sm mb-2 font-thin">aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h) und i) sowie Art. 9 Abs. 3 DSGVO;</li>
        <li className="list-disc text-sm mb-2 font-thin">zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
      </ul>
      <h3 className="font-bold text-lg">6.5.Recht auf Unterrichtung</h3>
      <p className="text-sm mb-2 font-thin">Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
      <h3 className="font-bold text-lg">6.6.Recht auf Datenübertragbarkeit</h3>
      <p className="text-sm mb-2 font-thin">Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
      <ul className="pl-8">
        <li className="list-disc text-sm mb-2 font-thin">die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1. S. 1 Satz 1 lit. a) DSGVO oder Art. 9 Abs. 2 lit. a) DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1. Satz 1 S. 1 lit. b) DSGVO beruht und</li>
        <li className="list-disc text-sm mb-2 font-thin">die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
      </ul>
      <p className="text-sm mb-2 font-thin">In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.</p>
      <h3 className="font-bold text-lg">6.7.Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
      <p className="text-sm mb-2 font-thin">Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
      <h3 className="font-bold text-lg">6.8.Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
      <p className="text-sm mb-2 font-thin">Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist, aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
      <p className="text-sm mb-2 font-thin">Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a) oder g) DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</p>
      <h3 className="font-bold text-lg">6.9.Widerspruchsrecht</h3>
      <strong className="font-bold text-sm leadong-none mb-2">Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1. Satz 1 S. 1 lit. e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</strong>
      <strong className="font-bold text-sm leadong-none mb-2">Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</strong>
      <strong className="font-bold text-sm leadong-none mb-2">Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong>
      <strong className="font-bold text-sm leadong-none mb-2">Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</strong>
      <strong className="font-bold text-sm leadong-none mb-2">Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</strong>
      <h3 className="font-bold text-lg">6.10.Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
      <p className="text-sm mb-2 font-thin">Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. Zuständige Aufsichtsbehörde für die datenschutzrechtlichen Fragen ist die Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen</p>
      <p className="text-sm mb-2 font-thin">
        Kavalleriestr. 2-4 <br />
        40213 Düsseldorf <br />
        Telefon 0211 / 38424-0 <br />
        Fax 0211 / 38424-10 <br />
        E-Mail: <a className="text-blue-600 hover:text-white" href="mailto:poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</a> <br />
        Internet: <a className="text-blue-600 hover:text-white" href="www.ldi.nrw.de">www.ldi.nrw.de</a>
      </p>
      <p className="text-sm mb-2 font-thin">Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.</p>
      <h2 className="font-bold text-lg">7.Veränderungen dieser App, Aktualisierung der Datenschutzerklärung</h2>
      <p className="text-sm mb-2 font-thin">Unsere App wird laufend weiterentwickelt. Daher aktualisieren wir diese Datenschutzerklärung von Zeit zu Zeit. Um auf dem Laufenden zu bleiben, empfehlen wir Ihnen diese Datenschutzerklärung daher regelmäßig zu besuchen. Ob Veränderungen vorliegen, erkennen Sie an dem unten genannten Stand.</p>
      <p className="text-sm mb-2 font-thin">Stand: 08/2022</p>
      </div>
    )
}