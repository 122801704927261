import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <>
      <div id="contact" className="relative pt-60 bg-grey h-full text-[20px] font-extd text-stone-800">
        <div className="w-1/2 relative left-0 right-0 ml-auto mr-auto">
          Hast du Fragen oder Anmerkungen? Kontaktiere uns.
          E-Mail: <a href="mailto:info@augmented-art-advertising.com" className="underline break-words underline-offset-2 
          hover:text-white transition-all">info@augmented-art-advertising.com</a> <br/> Rechtshinweise 
          findest du in unserem <Link to="/impressum" className="underline underline-offset-2 hover:text-white transition-all">Impressum</Link>.
        </div>
      </div>
    </>
  )
}