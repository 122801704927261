export default function Home() {
  return (
    <>
      <div id="home" className="overflow-hidden h-full w-full">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="500" height="500" viewBox="0 0 650 800"
          className="fill-yellow fixed top-[15%] bottom-0 left-0 right-0 m-auto">
          <polygon id="home_logo" points={[0, 0, 200, 100, 500, 400, 100, 500]} className="">
            <animate
              attributeType="XML"
              attributeName="points"
              values="0,85 500,0 330,750 110,600; 100,0 500,260 100,640 0,300; 285,0 645,385 260,680 0,0; 590,260 490,800 0,615 435,0; 330,750 110,600 0,85 500,0"
              dur="4s"
              keyTimes="0; 0.25; 0.5; 0.75; 1"
              calcMode="spline"
              keySplines="0 0 0.58 1; 0 0 0.58 1; 0 0 0.58 1; 0 0 0.58 1"
              repeatCount="indefinite" />
          </polygon>
        </svg>
        <h1 className="text-white  text-[48px]
						tracking-[0.5em] inline fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <a href="#about" className="">ad<span className="font-bold font-extd text-black">AR</span><span className="font-extrabold text-black">t</span></a>
        </h1>
      </div>
    </>
  )
}