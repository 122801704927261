import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import App from './App';
import OpenCall from './OpenCall';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz'; 
import ErweiterteWelten from './ErweiterteWelten';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="opencall" element={<OpenCall />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="Datenschutz" element={<Datenschutz />} />
        <Route path="erweitertewelten">
          <Route path="lippenlesen" element={<ErweiterteWelten name="lippenlesen" />}/>
          <Route path="intheflood" element={<ErweiterteWelten name="intheflood" />}/>
          <Route path="wasser" element={<ErweiterteWelten name="wasser" />}/>
          <Route path="rinde" element={<ErweiterteWelten name="rinde" />}/>
          <Route path="viet" element={<ErweiterteWelten name="viet" />}/>
          <Route path="karawane" element={<ErweiterteWelten name="karawane" />}/>
          <Route path="desktopspiral" element={<ErweiterteWelten name="desktopspiral" />}/>
          <Route path="shorba" element={<ErweiterteWelten name="shorba" />}/>
          <Route path="kubismus" element={<ErweiterteWelten name="kubismus" />}/>
          <Route path="orangesonfire" element={<ErweiterteWelten name="orangesonfire" />}/>
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

