import React, { useEffect, useState } from 'react';

export default function Navbar() {
  const navbarElements = ["news", "about", "contact"];

  const [, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  document.querySelectorAll("[data-navbar-elem]").forEach((btn) => {
    btn.classList.remove("font-extd");
    btn.classList.remove("tracking-[0.5em]");
    btn.classList.remove("text-white");
    btn.classList.add("text-stone-800");

  });
  let selected; 
  navbarElements.forEach((elem) => {
    const domElem = document.getElementById(elem);
    if (domElem != null && domElem.getBoundingClientRect().top <= 50) {
      selected = elem;
    }
  });
  document.querySelectorAll("[data-navbar-elem]").forEach((btn) => {
    if (selected === btn.innerText) {
      btn.classList.add("font-extd");
      btn.classList.add("tracking-[0.5em]");
      btn.classList.add("text-white");
      btn.classList.remove("text-stone-800");

    }
  })

  return (
    <>
      <nav className="text-[24px] w-full font-medium tracking-[0.2em] fixed top-0 z-10
        bg-grey backdrop-blur-sm">
        <div className="pl-6 text-stone-800 font-extd tracking-[0.05em] border-b-2 border-yellow">
          <a className="" href="#home"> AUGMENTED ART ADVERTISING</a>
        </div>
        <ul id="navbar" className="pl-6">
          {navbarElements.map((elem) => (
            <li key={elem} data-navbar-elem className="pr-12  text-stone-800  sm:hover:font-extd sm:hover:tracking-[0.5em] 
               lg:inline-block transition-all duration-100">
              <a href={"#" + elem}>{elem}</a>
            </li>
          ))}

        </ul>
      </nav>
    </>
  )
}