import Home from './Home';
import Navbar from './Navbar';
import News from './News'; 
import About2 from './About2';
import Contact from './Contact';

function App() {
  return (
    <div className="">
      <div className="bg-grey absolute w-full h-full overscroll-none">
        <Navbar />
        <Home />
        <News/>
        <About2 />
        <Contact />
      </div>
    </div>
  );
}

export default App;
