import AdArt from './AdArt';
import ad1 from './images/werbetafel.svg';
import logoMarl from "./images/marl-logo.jpg";
import logoCityscaper from "./images/cityscaper-logo.svg";
import logoKollektiv from "./images/kollektiv42-logo.svg";
import logoMedienwerk from "./images/medienwerk-logo.svg";
import logoFolkwang from "./images/folkwang-logo.png";
import logoNRW from "./images/nrw-logo-small.jpg";
// import lisa from "./images/lisa.svg";

export default function About() {
  return (
    <div id="canvas" className="relative right-0 left-0 w-full pl-[5%] pr-[5%] ml-auto mr-auto bg-grey text-stone-800">
      <div id="about" className=""></div>
      <div className=" font-extd text-[20px] leading-[1.3em] tracking-[0.05em]
          top-4 left-0 right-0 ml-auto mr-auto max-w-[94.4%] pr-[2.5%]">
        <div className="relative w-1/2 md:w-1/3 float-right pt-48">
          <img src={ad1} alt="" />
        </div>
        <p className="pt-48">
          Begeben Sie sich im Geiste auf die Straßen der Innenstadt. Was sehen
          Sie dort, abgesehen von Häusern, Passanten und Autos? – Werbung!
          Was der geübte Stadtmensch gut in die Peripherie seiner Wahrnehmung
          verlagern kann, wird der bewussten SpaziergängerIn doch deutlich
          auffallen:
          Gerade in stark frequentierten Zonen wie Innenstädten oder
          Hauptbahnhöfen, aber auch an Bushaltestellen und Gebäudewänden ist
          sie manifest.
        </p>
        <p>
          Dabei ist die eigens für die Anpreisung von Produkten geschaffene
          Infrastruktur durchaus beachtlich.
          Große Plakatwände, beleuchtete Tafeln, Litfaßsäulen. Doch was, wenn
          diese Ausstellungsflächen plötzlich einem kulturellen Zweck dienen?
        </p>


        <div className="relative">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1000" height="1000" viewBox="0 0 800 800"
            className="absolute top-96 md:-top-12 left-0 right-0 m-auto w-full">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: "#fce810", stopOpacity: "0" }} />
                <stop offset="100%" style={{ stopColor: "#fce810", stopOpacity: "1" }} />
              </linearGradient>
            </defs>
            <polygon id="home_logo" points={[0, 100, 800, 50, 700, 450, 150, 300]} fill="url(#grad1)" />
          </svg>


          <div className="relative mt-60 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-8 gap-12">
            <a href="https://vimeo.com/534019951#t=220s" target="_blank" rel="noopener noreferrer">
              <img id="logo-kollektiv" src={logoKollektiv} alt="" className="max-h-[90px] -translate-y-4 relative 
                left-0 right-0 mr-auto ml-auto transition-all cursor-pointer" title="https://vimeo.com/534019951#t=220s" />
            </a>
            <a href="https://cityscaper.de/" target="_blank" rel="noopener noreferrer">
              <img id="logo-cityscaper" src={logoCityscaper} alt="" className="max-h-[150px] relative 
              left-0 right-0 mr-auto ml-auto transition-all" title="https://cityscaper.de/" />
            </a>
            <a href="http://www.skulpturenmuseum-glaskasten-marl.de/" target="_blank" rel="noopener noreferrer" className="col-span-2">
              <img id="logo-marl" src={logoMarl} alt="" className="max-h-[150px] relative 
              left-0 right-0 mr-auto ml-auto transition-all" title="http://www.skulpturenmuseum-glaskasten-marl.de/" />
            </a>
            <a href="https://www.folkwang-uni.de/" target="_blank" rel="noopener noreferrer">
              <img id="logo-folkwang" src={logoFolkwang} alt="" className="lg:max-h-[60px] relative 
              left-0 right-0 mr-auto ml-auto transition-all" title="https://www.folkwang-uni.de/" />
            </a>
            <a href="https://www.medienwerk.nrw/" target="_blank" rel="noopener noreferrer">
              <img id="logo-medienwerk" src={logoMedienwerk} alt="" className="max-h-[150px] relative 
              left-0 right-0 mr-auto ml-auto transition-all" title="https://www.medienwerk.nrw/" />
            </a>
            <a href="https://www.mkw.nrw/" target="_blank" rel="noopener noreferrer" className="col-span-2">
              <img id="logo-nrw" src={logoNRW} alt="" className="max-h-[150px] relative 
              left-0 right-0 mr-auto ml-auto transition-all" title="https://www.mkw.nrw/" />
            </a>
          </div>


          <p className="mt-12 relative">
            <AdArt /> ist ein interdisziplinäres Medienkunstprojekt und ein
            gestalterisches Experiment zur Vermittlung kultureller Inhalte unter
            der
            Nutzung zeitgenössischer Technologien. Das <a href="http://www.skulpturenmuseum-glaskasten-marl.de/" title="http://www.skulpturenmuseum-glaskasten-marl.de/" target="_blank" rel="noopener noreferrer" className="underline underline-offset-2 hover:text-white transition-all" onMouseOver={() => { document.getElementById("logo-marl").classList.add("-translate-y-2") }} onMouseLeave={() => { document.getElementById("logo-marl").classList.remove("-translate-y-2") }}>Skulpturenmuseum Glaskasten Marl</a>,
            das Essener Medienkunst-Duo <a href="https://vimeo.com/534019951#t=220s" title="https://vimeo.com/534019951#t=220s" target="_blank" rel="noopener noreferrer" className="underline underline-offset-2  hover:text-white transition-all cursor-pointer" onMouseOver={() => { document.getElementById("logo-kollektiv").classList.add("-translate-y-6") }} onMouseLeave={() => { document.getElementById("logo-kollektiv").classList.remove("-translate-y-6") }}>Kollektiv 42</a>,
            die Aachener <a href="https://cityscaper.de/" title="https://cityscaper.de/" target="_blank" rel="noopener noreferrer" className="underline underline-offset-2  hover:text-white transition-all" onMouseOver={() => { document.getElementById("logo-cityscaper").classList.add("-translate-y-2") }} onMouseLeave={() => { document.getElementById("logo-cityscaper").classList.remove("-translate-y-2") }}>cityscaper GmbH</a> und
            die <a href="https://www.folkwang-uni.de/" title="https://www.folkwang-uni.de/" target="_blank" rel="noopener noreferrer" className="underline underline-offset-2 hover:text-white transition-all" onMouseOver={() => { document.getElementById("logo-folkwang").classList.add("-translate-y-2") }} onMouseLeave={() => { document.getElementById("logo-folkwang").classList.remove("-translate-y-2") }}>Folkwang Universität der Künste</a> planen mit
            einem innovativen Augmented-Reality-Projekt die virtuelle Erweiterung
            des musealen Raumes auf die Ausstellungsarchitektur von Werbung im
            öffentlichen Raum. Dabei gilt das Interesse nicht nur der kritischen
            Auseinandersetzung mit der strukturellen Omnipräsenz von
            Produkt-Marketing. Ebenso entscheidend ist der intensive,
            branchenübergreifende Diskurs über die technischen Möglichkeiten und
            medialen Schwierigkeiten von Mixed-Reality-Systemen.
          </p>
          <p className="relative">
            <AdArt />ist gefördert durch das <a href="https://www.medienwerk.nrw/" title="https://www.medienwerk.nrw/" target="_blank" rel="noopener noreferrer" className="underline underline-offset-2 hover:text-white transition-all" onMouseOver={() => { document.getElementById("logo-medienwerk").classList.add("-translate-y-2") }} onMouseLeave={() => { document.getElementById("logo-medienwerk").classList.remove("-translate-y-2") }}>Medienwerk NRW</a> und
            das <a title="https://www.mkw.nrw/" href="https://www.mkw.nrw/" target="_blank" rel="noopener noreferrer" className="underline underline-offset-2 hover:text-white transition-all" onMouseOver={() => { document.getElementById("logo-nrw").classList.add("-translate-y-2") }} onMouseLeave={() => { document.getElementById("logo-nrw").classList.remove("-translate-y-2") }}>Ministerium für Kultur und Wissenschaft des Landes NRW</a>.
          </p>
        </div>
        {/* <img src={lisa} alt="" className="w-[500px] m-24" onMouseOver={() => {
          const mask = document.getElementById("a"); 
          console.log(mask);
        }}/> */}
        <p className="mt-60 pb-60">
          Mit einer frei verfügbaren App können Interessierte in ganz NRW ab
          Juli die Werbeplakate in ihrer Nachbarschaft als Ausstellungsflächen
          einer erweiterten Realität erleben, Sammlungen ihrer
          Lieblingskunstwerke erstellen und mehr über die KünstlerInnen
          erfahren.
          Im August 2022 lädt das Skulpturenmuseum Glaskasten Marl dann zu
          einem besonderen Highlight ein: der virtualisierten Sammlung sowie
          der temporären <AdArt />-Ausstellung mit eigens für den AR-Raum
          entwickelten Kunstwerken. BesucherInnen können die Ausstellung mit
          der App auf eigene Faust erkunden oder mit Tablets ausgestattet an
          kuratierten Führungen in der Innenstadt teilnehmen.
        </p>
      </div>
    </div >

  )
}