import PlakatEW from './images/Marl_Poster_Druck.jpg';
import AdArt from './AdArt';
import RouteMarl from './images/Marl_Route.pdf';
import AppIon from './images/App_Icon_1.png'
import SpekWelten from './images/Poster_Spekulative_Welten_Erweiterte_Realitaeten.jpg';
import SympProg from './images/Symposium_Programm.pdf';


export default function News() {
  return (
    <div className="relative right-0 left-0 w-full pl-[5%] pr-[5%] ml-auto mr-auto bg-grey text-stone-800">
      <div id="news" className="absolute -top-24"></div>

      <div className="md:w-2/3 font-extd text-[20px] leading-[1.3em] tracking-[0.05em]
      pt-4 left-0 right-0 ml-auto mr-auto max-w-[94.4%] pr-[2.5%] gap-8 mb-24">
        <h2 className="font-bold mb-4 "> Spekulative Welten, Erweiterte Realitäten</h2>
        <div className="ml-auto mr-auto mb-4">
          <img src={SpekWelten} alt="App Icon" className="w-full"/>
        </div>
        <p>
            Die Folkwang Universität der Künste und das Kollektiv 42 laden im SANAA-Gebäude in Essen
            ein zu einem interdisziplinären Symposium zum Thema Augmented Reality. Dort gibt es 
            unter Anderem technische und konzeptionelle Insides in das <AdArt/>- Projekt. Die Teilnahme ist 
            kostenlos, eine Anmeldung ist nicht erforderlich. 
            Das Programm als PDF ist gibt es <a href={SympProg} target="_blank" rel="noopener noreferrer" className="light-link">hier</a>.
          </p>
      </div>

      <div className="font-extd text-[20px] leading-[1.3em] tracking-[0.05em]
      pt-24 left-0 right-0 ml-auto mr-auto max-w-[94.4%] pr-[2.5%] md:grid md:grid-cols-3 gap-8 mb-24">
        <div className="col-span-2">
          <h2 className="font-bold mb-4"> <AdArt/> Release in den Stores</h2>
          <p>
            Ab sofort steht die erste Version der Augmented Reality App <AdArt/> 
            für <a href="https://play.google.com/store/apps/details?id=com.cityscaper.adart&hl=de" target="_blank" rel="noopener noreferrer" className="light-link">Android</a> und <a href="https://apps.apple.com/de/app/adart/id1634440957" target="_blank" rel="noopener noreferrer" className="light-link">iOS</a> zum 
            Download bereit! 
          </p>
        </div>
        <div>
          <img src={AppIon} alt="App Icon" className="rounded-xl w-24"/>
        </div>
      </div>

      <div className="font-extd text-[20px] leading-[1.3em] tracking-[0.05em]
      pt-24 left-0 right-0 ml-auto mr-auto max-w-[94.4%] pr-[2.5%] md:grid md:grid-cols-3 gap-8">
        <img src={PlakatEW} alt="" className="mb-4" />
        <div className="col-span-2">
          <h2 className="font-bold mb-4">Erweiterte Welten ‒ urbane Medienkunst in Marl</h2>
          <p> 
            Erlebt die erste Mixed-Reality-Ausstellung mit der <AdArt/>-App mit Medienkunstwerken
            von Studierenden der Folkwang Universität der Künste sowie jungen MedienkünstlerInnen aus NRW
            vom 12. - 28. August 2022 in Marl.
          </p>
          <p>Weitere Informationen: <a href="http://www.skulpturenmuseum-glaskasten-marl.de/de/ausstellungen/time/future/2093.html"
              target="_blank" 
              rel="noopener noreferrer"
              className="light-link">Erweiterte Welten</a>
          </p>
          <p className="pt-4">Die Standorte der Medienkunstwerke könnt ihr mithilfe unserer <a href={RouteMarl} target="_blank" rel="noopener noreferrer" className="light-link">Karte</a> finden.
          </p>
        </div>
      </div>
      
    </div>
  )
}